import * as Sentry from "@sentry/browser";
import { Integrations as TracingIntegrations } from "@sentry/tracing";
import createSentryMiddleware from "redux-sentry-middleware";

import breadcrumbsFilter from "./breadcrumbs_filter";
import config from "./config";
import apolloLink from "./apollo_link";
import { getEnv } from "app/env";

const { dsn, ignoreErrors } = config;

const SENTRY_TRACES_SAMPLE_RATE = Number(getEnv("SENTRY_TRACES_SAMPLE_RATE") || "0.0");

const install = () => {
  if (dsn) {
    Sentry.init({
      dsn,
      environment: getEnv("SENTRY_ENVIRONMENT") || getEnv("APP_ENV"),
      initialScope: {
        tags: { app_env: getEnv("APP_ENV"), owner: getEnv("OWNER") },
      },
      release: getEnv("SENTRY_RELEASE"),
      ignoreErrors,
      beforeBreadcrumb: breadcrumbsFilter,
      autoSessionTracking: true,
      integrations: [
        new Sentry.Integrations.GlobalHandlers({
          onunhandledrejection: false,
        }),
        new TracingIntegrations.BrowserTracing(),
      ],
      tracesSampler: () => {
        if (SENTRY_TRACES_SAMPLE_RATE <= 0.0 || isNaN(SENTRY_TRACES_SAMPLE_RATE)) {
          return false;
        }

        return SENTRY_TRACES_SAMPLE_RATE;
      },
    });
    Sentry.configureScope(scope => {
      scope.setLevel("error");
    });
  }
};

const withScope = (options, callback) => {
  if (dsn) {
    const { extra, level } = options || {};

    Sentry.withScope(scope => {
      if (extra) {
        scope.setExtra(extra);
      }
      if (level) {
        scope.setLevel(level || "error");
      }
      callback();
    });
  }
};

const captureException = (error, options) => {
  withScope(options, () => Sentry.captureException(error));
};

const captureMessage = (message, options) => {
  withScope(options, () => Sentry.captureMessage(message, options));
};

const setUserContext = userId => {
  if (dsn) {
    Sentry.configureScope(scope => {
      scope.setUser({ id: userId });
    });
  }
};

const reduxMiddleware = createSentryMiddleware(Sentry, {
  stateTransformer: _state => ({}),
  breadcrumbDataFromAction: action => {
    try {
      return {
        payload: JSON.stringify(action.payload),
      };
    } catch (error) {
      captureException(error, { level: "info" });
    }
  },
});

export default {
  install,
  captureException,
  captureMessage,
  setUserContext,
  reduxMiddleware,
  apolloLink,
};
