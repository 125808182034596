import * as Sentry from "@sentry/browser";
import { ApolloLink } from "@apollo/client";

import { getEnv } from "app/env";

const operationInfo = operation => ({
  type: operation.query.definitions.find(def => def.operation).operation,
  name: operation.operationName,
  fragments: operation.query.definitions
    .filter(def => def.kind === "FragmentDefinition")
    .map(def => def.name.value)
    .join(", "),
});

// Instrument Sentry with data from Apollo via providing operations data
const apolloLink = new ApolloLink((operation, forward) => {
  if (getEnv("NODE_ENV") === "development") {
    // eslint-disable-next-line no-console
    console.log(operationInfo(operation));
  } else {
    Sentry.addBreadcrumb({
      category: "graphql",
      data: operationInfo(operation),
      level: "debug",
    });
  }

  return forward(operation);
});

export default apolloLink;
