import { getEnv } from "app/env";

export default {
  dsn: getEnv("TESTER_WORK_SENTRY_DSN"),
  ignoreErrors: [
    /Something went wrong, please try again./,
    /ResizeObserver loop limit exceeded/,
    /ResizeObserver loop completed with undelivered notifications/,
    /You need to sign in before continuing./,
    /NetworkError/,
    /Failed to execute 'removeChild' on 'Node'/,
    /this.remove is not a function/,
    /Cannot read property '_avast_submit' of undefined/, // avast extension error
    /Cannot read property 'getReadMode.*' of undefined/, // unknown source, not related to our app https://stackoverflow.com/questions/64175183/what-does-these-error-means-getreadmodeconfig-getreadmoderender-getreadmodeext

    // the list below is based on https://docs.sentry.io/clients/javascript/tips/#decluttering-sentry
    // Random plugins/extensions
    "top.GLOBALS",
    // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
    "originalCreateNotification",
    "canvas.contentDocument",
    "MyApp_RemoveAllHighlights",
    "http://tt.epicplay.com",
    "Can't find variable: ZiteReader",
    "jigsaw is not defined",
    "ComboSearch is not defined",
    "http://loading.retry.widdit.com/",
    "atomicFindClose",
    // Facebook borked
    "fb_xd_fragment",
    // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
    // reduce this. (thanks @acdha)
    // See http://stackoverflow.com/questions/4113268
    "bmi_SafeAddOnload",
    "EBCallBackMessageReceived",
    // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
    "conduitPage",
    /.*translate\.goog.*/,
    /.*SecurityError: Blocked a frame with origin.*from accessing a cross-origin frame.*/,
    // This issue occurs exclusively on Apple devices, most likely during the unmounting of components.
    // Remove this entry after migrating to React 18.
    "NotFoundError: The object can not be found here.",
  ],
  ignoreUrls: [
    // Facebook flakiness
    /graph\.facebook\.com/i,
    // Facebook blocked
    /connect\.facebook\.net\/en_US\/all\.js/i,
    // Woopra flakiness
    /eatdifferent\.com\.woopra-ns\.com/i,
    /static\.woopra\.com\/js\/woopra\.js/i,
    // Chrome extensions
    /extensions\//i,
    /^chrome:\/\//i,
    // Other plugins
    /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
    /webappstoolbarba\.texthelp\.com\//i,
    /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
    /translate\.goog/,
  ],
};
