import React from "react";
import PropTypes from "prop-types";
import { Heading } from "@hopper/heading";

import Sentry from "../sentry";

export default class SentryExceptionBoundary extends React.Component {
  static propTypes = {
    // Called when Component catches an error.
    // Passes 2 arguments - error itself plus additional info as second argument
    onError: PropTypes.func,
  };

  state = { error: null };

  componentDidCatch(error, errorInfo) {
    Sentry.captureException(error, errorInfo);
    this.setState({ error });

    if (this.props.onError) {
      this.props.onError(error, errorInfo);
    }
  }

  render() {
    if (this.state.error) {
      return (
        <div className="px-lg">
          <Heading size={1}>We are sorry, something went wrong.</Heading>
          <p>
            Please try to refresh in few seconds. Don't hesitate to contact us if that will not get fixed in next few
            minutes.
          </p>
        </div>
      );
    }

    return this.props.children;
  }
}
